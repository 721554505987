import { useSearchParams } from "next/navigation";
import { AnchorHTMLAttributes, useMemo } from "react";

import CrossCountry from "~/types/cross-country";

type CultureLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
  country: CrossCountry.CountryType;
  culture: CrossCountry.CultureType;
};

export function CultureLink(props: CultureLinkProps) {
  const { children, country, culture, ...rest } = props;

  const searchParams = useSearchParams();
  const utmParameters = useMemo(() => {
    const parameters: string[] = [];

    searchParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        parameters.push(`${key}=${value}`);
      }
    });

    return parameters.length > 0 ? `?${parameters.join("&")}` : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  return country.isUnifiedCommerce ? (
    <a {...rest} href={`/${culture.locale}${utmParameters}`}>
      {children}
    </a>
  ) : (
    <a {...rest} href={culture.href + utmParameters} target="_blank">
      {children}
    </a>
  );
}
