import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useReducer, useState } from "react";

import appRoutes from "~/utils/app-routes";

type Action =
  | { type: "added"; payload: { timestamp: Date } }
  | { type: "addedAll"; payload: { timestamp: Date; moreText?: ReactNode } }
  | { type: "fixed"; payload: { timestamp: Date } }
  | { type: "oos"; payload: { timestamp: Date } }
  | { type: "modified"; payload: { timestamp: Date } }
  | { type: "removed"; payload: { timestamp: Date } }
  | { type: "removedAllOOSDelivery"; payload: { timestamp: Date } }
  | { type: "reset" };

type State =
  | { status: "added"; timestamp: Date }
  | { status: "addedAll"; timestamp: Date; moreText?: ReactNode }
  | { status: "fixed"; timestamp: Date }
  | { status: "oos"; timestamp: Date }
  | { status: "modified"; timestamp: Date }
  | { status: "removed"; timestamp: Date }
  | { status: "removedAllOOSDelivery"; timestamp: Date }
  | { status: undefined };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "added":
      return { status: "added", timestamp: action.payload.timestamp };
    case "addedAll":
      return { status: "addedAll", timestamp: action.payload.timestamp, moreText: action.payload.moreText };
    case "fixed":
      return { status: "fixed", timestamp: action.payload.timestamp };
    case "oos":
      return { status: "oos", timestamp: action.payload.timestamp };
    case "modified":
      return { status: "modified", timestamp: action.payload.timestamp };
    case "removed":
      return { status: "removed", timestamp: action.payload.timestamp };
    case "removedAllOOSDelivery":
      return { status: "removedAllOOSDelivery", timestamp: action.payload.timestamp };
    case "reset":
      return { status: undefined };
  }
}

// @ts-expect-error - So that we can initialize the context as undefined
const Context = createContext<{
  state: State;
  isHovering: boolean;
  notifyAdded: (timestamp: Date) => void;
  notifyAddedAll: (timestamp: Date, moreText?: ReactNode) => void;
  notifyFixed: (timestamp: Date) => void;
  notifyOOS: (timestamp: Date) => void;
  notifyModified: (timestamp: Date) => void;
  notifyRemoved: (timestamp: Date) => void;
  notifyRemovedAllOOSDelivery: (timestamp: Date) => void;
  resetNotifications: () => void;
  setHovering: (hovering: boolean) => void;
}>();

export default function CartNotificationProvider(props: { children: React.ReactNode }) {
  const [isHovering, setHovering] = useState(false);
  const [state, dispatch] = useReducer(reducer, { status: undefined });
  const router = useRouter();

  function notifyAdded(timestamp: Date) {
    if (router.route != appRoutes.CART) {
      dispatch({ type: "added", payload: { timestamp } });
    }
  }

  function notifyAddedAll(timestamp: Date, moreText?: ReactNode) {
    dispatch({ type: "addedAll", payload: { timestamp, moreText: moreText ?? <></> } });
  }

  function notifyFixed(timestamp: Date) {
    dispatch({ type: "fixed", payload: { timestamp } });
  }

  function notifyOOS(timestamp: Date) {
    dispatch({ type: "oos", payload: { timestamp } });
  }

  function notifyModified(timestamp: Date) {
    if (router.route != appRoutes.CART) {
      dispatch({ type: "modified", payload: { timestamp } });
    }
  }

  function notifyRemoved(timestamp: Date) {
    if (router.route != appRoutes.CART) {
      dispatch({ type: "removed", payload: { timestamp } });
    }
  }

  function notifyRemovedAllOOSDelivery(timestamp: Date) {
    dispatch({ type: "removedAllOOSDelivery", payload: { timestamp } });
  }

  function resetNotifications() {
    dispatch({ type: "reset" });
  }

  return (
    <Context.Provider
      value={{
        state,
        isHovering,
        notifyAdded,
        notifyAddedAll,
        notifyFixed,
        notifyOOS,
        notifyModified,
        notifyRemoved,
        notifyRemovedAllOOSDelivery,
        resetNotifications,
        setHovering,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export function useCartNotification() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useCartNotification must be used within a CartNotificationProvider`);
  }

  return context;
}
