import { state } from "@springtree/eva-sdk-react-recoil";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { useConfiguration } from "~/contexts/configuration";
import { useCart } from "~/hooks/use-cart";
import Logger from "~/services/logger/logger";

/**
 * Keeps track of the current organization unit and the cart's organization unit.
 * Removes the current cart if the two don't match.
 */
export default function CartChecker() {
  const { organizationUnitId } = useConfiguration();
  const cart = useCart();
  const cartOrganizationUnitId = cart?.ShoppingCart.OriginatingOrganizationUnitID;

  const resetShoppingCartRequest = useSetRecoilState(state.checkout.shoppingCartState.request);

  useEffect(() => {
    let mounted = true;

    if (cartOrganizationUnitId && `${cartOrganizationUnitId}` !== `${organizationUnitId}`) {
      if (mounted) {
        Logger.instance.debug(
          `Resetting shopping cart originated in ${cartOrganizationUnitId}: the user is now in ${organizationUnitId}`
        );
        resetShoppingCartRequest(undefined);
      }
    }

    return () => {
      mounted = false;
    };
  }, [resetShoppingCartRequest, organizationUnitId, cartOrganizationUnitId]);

  return null;
}
