import { BasketType, ProductActionOutcome } from "~/components/common/product-actions";
import { Breadcrumb } from "~/components/product/product-breadcrumbs";
import Algolia from "~/types/algolia";
import CrossCountry from "~/types/cross-country";
import Editorials from "~/types/editorials";
import {
  BaseLineItemTrackingObject,
  CategorizedCartItems,
  CategoryPageTrackingObject,
  ConfirmationPageTrackingObject,
  EcommerceTrackingItemConditionalAttrs,
  EcommerceTrackingItemModel,
  EcommerceTrackingModel,
  PageTrackingOptions,
  ProductPageTrackingObject,
  TrackingOptions,
} from "~/types/trackings.models";

import { ClientUtils } from "./client-utils";

declare const window: Window & { dataLayer: any[] };

interface CategoryProps {
  page: Editorials.ProductListingPage;
}

interface ProductProps {
  selected: CrossCountry.DetailedProduct;
  root: CrossCountry.DetailedProduct;
  categories: Breadcrumb[];
}

interface ConfirmationProps {
  page: Editorials.SpecialPage | null;
}

const ADYEN_PAYMENTTYPE_MAPPING: Map<string, string> = new Map([["scheme", "Credit Card"]]);

export default class TrackingUtils {
  private static readonly _basketTrackAction: Record<ProductActionOutcome, string> = {
    added: "add_to",
    removed: "remove_from",
  };

  /**
   * Extracts line items data by type
   * @param {EVA.Core.OrderDto} cart the cart to extract line items data from
   * @returns line items information by type
   */
  static extractLineItemsData(cart: EVA.Core.OrderDto | null) {
    const coupons: string[] = [];
    const promosMapping: Record<number, string[]> = {};
    const shippingCosts: number[] = [];
    cart?.Lines.forEach((l) => {
      if (l.Type === 1) {
        if (!!l.DiscountCouponCode) {
          coupons.push(l.DiscountCouponCode);
        }
        if (l.ParentID) {
          // prepare mapping of promos to lineitems
          promosMapping[l.ParentID] = promosMapping[l.ParentID] || [];
          promosMapping[l.ParentID].push(l.Description);
        }
      } else if (l.Type === 5) {
        shippingCosts.push(l.TotalAmountInTax);
      }
    });
    return { coupons, promosMapping, shippingCosts };
  }

  /**
   * Helper method to split cart line items based on type, binding related products together
   * @param {EVA.Core.OrderDto} cart the cart to split lines from
   * @param {Record<string, any>} parentsData Products parents data originally missing in cart products
   * @returns {CategorizedCartItems} list of cart items splitted by type
   */
  private static _categorizeLineItems(
    cart: EVA.Core.OrderDto,
    parentsData?: Record<string, any>
  ): CategorizedCartItems {
    const products: EcommerceTrackingItemModel[] = [];
    const { coupons, promosMapping, shippingCosts } = this.extractLineItemsData(cart);

    cart?.Lines.filter((l) => l.Type === 0).forEach((l) => {
      const mappedItem = this.mapEcomCartItem(l, l.ProductID ? parentsData?.[l.ProductID!] : undefined);
      const appliedPromos = promosMapping[l.ID];
      if (appliedPromos) {
        mappedItem.coupon = appliedPromos.join(",");
      }
      products.push(mappedItem);
    });

    return { products, coupons, shippingCosts };
  }

  /**
   * Collects requested tracking info from a product
   * @param root Root product data
   * @param variant Variant product data
   * @param {EcommerceTrackingItemConditionalAttrs} extraAttrs Extra data to add to item tracking object
   * @returns {EcommerceTrackingItemModel} Mapped object with tracking data
   */
  static mapEcomItem(
    root: any,
    variant?: any,
    extraAttrs?: EcommerceTrackingItemConditionalAttrs
  ): EcommerceTrackingItemModel {
    if (!root) {
      // 2024-03-22 Hotfix for missing product line properties.
      return {} as unknown as EcommerceTrackingItemModel;
    }

    const variantOrRootDisplayPrice = variant?.display_price || root?.display_price;
    const variantOrRootPrice = variant?.original_price || root?.original_price || variantOrRootDisplayPrice;
    const discount = variantOrRootPrice
      ? Math.abs(Number.parseFloat(variantOrRootPrice) + variantOrRootDisplayPrice)
      : 0;

    const categoriesHierarchy: string[] = root?.ecomm_online_category_master?.split("_") || [];

    const trackObj: EcommerceTrackingItemModel = {
      item_id: root.backend_id,
      item_name: root.display_value,
      item_brand: "KIKO",
      price: variant?.display_price || root.display_price,
      currency: variant?.currency_id || root.currency_id,
      item_variant: variant?.color || root.color || "no_variant",
      ...extraAttrs,
    };

    if (categoriesHierarchy[0]) trackObj.item_category = categoriesHierarchy[0];
    if (categoriesHierarchy[1]) trackObj.item_category2 = categoriesHierarchy[1];
    if (categoriesHierarchy[2]) trackObj.item_category3 = categoriesHierarchy[2];

    if (discount > 0) {
      trackObj.discount;
    }

    return trackObj;
  }

  static mapAlgoliaProduct(algoliaProduct: any, extraAttrs?: EcommerceTrackingItemConditionalAttrs) {
    const trackObj: EcommerceTrackingItemModel = {
      item_id: algoliaProduct.baseBackendId ?? algoliaProduct.code,
      item_name: algoliaProduct.name,
      item_brand: "KIKO",
      currency: algoliaProduct.currency,
      price: algoliaProduct.prices?.value,
      item_variant: algoliaProduct.color || "no_variant",
      ...extraAttrs,
    };
    const categoriesHierarchy = algoliaProduct.category?.split("_") || [];
    if (categoriesHierarchy[0]) trackObj.item_category = categoriesHierarchy[0];
    if (categoriesHierarchy[1]) trackObj.item_category2 = categoriesHierarchy[1];
    if (categoriesHierarchy[2]) trackObj.item_category3 = categoriesHierarchy[2];

    if (algoliaProduct.prices?.formattedDiscountPerc > 0) {
      trackObj.discount;
    }

    return trackObj;
  }

  /**
   * Collects requested tracking info from a cart item
   * @param {EVA.Core.OrderLineDto} line Product line item in cart
   * @param {any} root Root product information
   * @returns {EcommerceTrackingItemModel} Mapped object with tracking data
   */
  static mapEcomCartItem(line: EVA.Core.OrderLineDto, root?: any): EcommerceTrackingItemModel {
    return {
      ...this.mapEcomItem(root || line.Product?.Properties, root ? line.Product?.Properties : undefined),
      discount: line.DiscountAmount ? Math.abs(line.DiscountAmount / line.QuantityOpen) : 0,
      quantity: line.QuantityOpen,
    } as EcommerceTrackingItemModel;
  }

  /**
   * Helper method to keep base ecom payload definition cleaner
   * @param {Record<string, any>} base the base event tracking payload
   * @param {EcommerceTrackingModel} extension the ecommerce section to add
   * @returns the expanded object
   */
  private static _expandEcommercePayload(base: Record<string, any> = {}, extension: EcommerceTrackingModel = {}) {
    return { ...base, ecommerce: extension };
  }

  /**
   * Maps info from a cart
   * @param {string} event Event id
   * @param {EVA.Core.OrderDto} cart Cart data
   * @param {Record<string, any>} parentsData Products parents data originally missing in cart products
   * @param {Record<string, any>} payload Extra data to add to tracking object
   * @returns {Record<string, any>} updated tracking payload
   */
  static mapEcomCart(
    event: string,
    cart?: EVA.Core.OrderDto,
    parentsData?: Record<string, any>,
    payload?: Record<string, any>
  ): Record<string, any> {
    if (!cart) {
      return {};
    }
    const { products, coupons } = this._categorizeLineItems(cart, parentsData);
    const expPayload = this._expandEcommercePayload(undefined, {
      ...payload,
      currency: cart?.CurrencyID,
      value: cart?.TotalAmountInTax,
    });

    if (coupons.length) {
      expPayload.ecommerce.coupon = coupons.join(",");
    }

    return this.mapEcommerceEvent(event, products, expPayload);
  }

  /**
   * Maps info from an order
   * @param {string} event Event id
   * @param {EVA.Core.OrderDto} order Order data
   * @param {Record<string, any>} parentsData Root product data to add to tracking object
   * @param {Record<string, any>} payload Extra data to add to tracking object
   * @returns {Record<string, any>} updated tracking payload
   */
  static mapEcomOrder(
    event: string,
    order: EVA.Core.OrderDto | null | undefined,
    parentsData?: Record<string, any>,
    payload?: Record<string, any>
  ): Record<string, any> {
    if (!order) {
      return {};
    }
    const { products, coupons, shippingCosts } = this._categorizeLineItems(order, parentsData);
    //email: KIKO - Segnalazioni tracciamenti goLive IT. payment_type tracking removed as indicated. Commented in case they want to add it again.
    //const paymentType = order?.Payments.map((paymentMethod) => paymentMethod.Description).join(",");
    const expPayload = this._expandEcommercePayload(payload, {
      currency: order?.CurrencyID,
      value: order?.TotalAmountInTax,
      tax: order?.TotalTaxAmount,
      transaction_id: order?.Barcode,
      //payment_type: paymentType.length ? paymentType : "Pay in store",
      shipping: shippingCosts.reduce((a, v) => a + v, 0),
    });

    if (coupons.length) {
      expPayload.ecommerce.coupon = coupons.join(",");
    }

    return this.mapEcommerceEvent(event, products, expPayload);
  }

  /**
   * Generic user event tracking payload mapper helper method
   * @param {string} event Event identifier
   * @param {Record<string, any>} payload Data to track
   * @returns {Record<string, any>} updated tracking payload
   */
  static mapUserEvent(event: string, payload?: Record<string, any>): Record<string, any> {
    return { event: event, ...payload };
  }

  /**
   * Generic ecom event tracking payload mapper helper method
   * @param {string} event Event identifier
   * @param {EcommerceTrackingItemModel[]} items Items to track data for
   * @param {Record<string, any>} payload Data to track
   * @returns {Record<string, any>} updated tracking payload
   */
  static mapEcommerceEvent(
    event: string,
    items: EcommerceTrackingItemModel[] = [],
    payload: Record<string, any> = {}
  ): Record<string, any> {
    if (event && items?.length) {
      payload.ecommerce = { ...payload.ecommerce, ...{ items } };
      payload.event = event;
    }
    return payload;
  }

  /**
   * Helper method to map items in-basket status events
   * @param {BasketType} typ Type of basket (cart or wishlist)
   * @param {keyof BasketTrackAction} act Action to track
   * @param {string} scp Identifies where the event has been triggered from
   * @param itm Data of the item to track
   * @returns {Record<string, any> | undefined} updated tracking payload
   */
  static mapBasketAction(
    typ: BasketType,
    act: ProductActionOutcome,
    scp: string,
    itm: any
  ): Record<string, any> | undefined {
    if (act && act in this._basketTrackAction) {
      const event = `${this._basketTrackAction[act as ProductActionOutcome]}_${typ}`;
      return this.mapEcommerceEvent(event, [itm], {
        [`${event}_place`]: scp,
      });
    }
  }

  /**
   * Maps tracking data from cart line items
   * @param {EVA.Core.ShoppingCartResponse} cart The source of data
   * @returns {BaseLineItemTrackingObject[]} The mapped line items data
   */
  static mapPageCartItems(cart?: EVA.Core.OrderDto, parentsData?: Record<string, any>): BaseLineItemTrackingObject[] {
    return (
      cart?.Lines.filter((e) => e.Type === 0).map((pli) => {
        return {
          product_id: pli.Product?.BackendID,
          product_price: pli.NetTotalAmountInTax,
          product_name: pli.Product?.Properties?.display_value,
          product_quantity: pli.QuantityOpen,
        } as BaseLineItemTrackingObject;
      }) || []
    );
  }

  static mapTrackCategoryPage(props: Record<string, any>): Record<string, any> {
    const title = props.page?.category.fields.name;
    const subCategory = props.page?.category.fields.name;
    const mainCategory = props.breadcrumbs?.[0]?.text || subCategory;
    const productSet = props.page?.category.fields.productSet;
    const extension: CategoryPageTrackingObject = {
      category_id: props.page?.category.fields?.categoryId || productSet?.fields?.internalName,
      product_set: !!productSet,
      category_name: mainCategory,
    };
    if (mainCategory !== subCategory) {
      extension.subcategory_name = subCategory;
      extension.page_type_section = "subcategory";
    } else {
    }
    return { title, extension };
  }

  static mapTrackEditorialPage(props: Record<string, any>): Record<string, any> {
    const title = props.page.seoMetadata?.fields.seoTitle;
    const slugs = props.page.slug.split("/");
    const extension =
      slugs && slugs.length
        ? {
            page_type_section: "editorial-" + slugs[slugs.length - 1],
          }
        : {};
    return { title, extension };
  }

  static mapTrackConfirmationPage(props: ConfirmationProps, order: EVA.Core.OrderDto | null): Record<string, any> {
    const trackOptions: PageTrackingOptions = { title: props.page?.seoMetadata?.fields.seoTitle };
    if (order) {
      const { coupons, shippingCosts } = TrackingUtils.extractLineItemsData(order);
      const [order_revenue, order_discount] = order.Lines.reduce(
        (a, v) => {
          return [a[0] + v.TotalAmountInTax, a[1] + v.DiscountAmount];
        },
        [0, 0]
      );

      trackOptions.extension = {
        order_number: order.Barcode,
        order_revenue,
        order_discount,
        order_discount_code: coupons.join(","),
        order_shipping: shippingCosts.reduce((a, v) => a + v, 0),
        order_products: this.mapPageCartItems(order),
      } as ConfirmationPageTrackingObject;
    }
    return trackOptions;
  }

  static mapTrackProductPage(props: ProductProps): Record<string, any> {
    const title = props.root.display_value;
    const extension: ProductPageTrackingObject = {
      product_id: props.selected.backend_id,
      product_name: props.selected.display_value,
      product_price: props.selected.display_price,
      product_category_id: (props.root as any)?.ecomm_online_category_master,
      product_category_name: props.categories?.[props.categories?.length - 1]?.text,
    };

    return { title, extension };
  }

  /**
   * Formats a boolean as a string, as requested for trackings
   * @param {boolean} value Boolean valie to format
   * @param {boolean }asLetter Formats given value in Y or N format
   * @returns The formatted value
   */
  static formatBoolean(value: boolean, asLetter?: boolean): string {
    if (asLetter) {
      return value ? "Y" : "N";
    }
    return String(value);
  }

  /**
   * Tracks given products impressions from given index
   * @param products products list
   * @param lastTrackedIndex last tracked product index
   */
  private static _trackItemsListImpressions<T>(
    products: T[],
    lastTrackedIdx: number,
    context: string,
    mapFn: (p: T, i: number, context: string) => EcommerceTrackingItemModel
  ): number {
    if (products?.length > lastTrackedIdx) {
      const productsTracking = [];
      for (let i = lastTrackedIdx; i < products.length; i++) {
        const p = products[i];
        productsTracking.push(mapFn(p, i, context));
      }
      TrackingUtils.track(
        TrackingUtils.mapEcommerceEvent("view_item_list", productsTracking, {
          user_action: "impression",
        })
      );
      return products.length;
    }
    return lastTrackedIdx;
  }

  static trackPLPImpression(products: CrossCountry.Product[], lastTrackedIndex: number, context: string): number {
    return TrackingUtils._trackItemsListImpressions<CrossCountry.Product>(
      products,
      lastTrackedIndex,
      context,
      (p, i, context) => TrackingUtils.mapEcomItem(p, p.children?.[0], { index: i, item_list_name: context })
    );
  }

  static trackAlgoliaProductsListImpression(
    products: Algolia.Product[],
    lastTrackedIdx: number,
    context: string,
    options: Record<string, any> = {}
  ): number {
    return TrackingUtils._trackItemsListImpressions<Algolia.Product>(
      products,
      lastTrackedIdx,
      context,
      (p, i, context) =>
        TrackingUtils.mapAlgoliaProduct({ ...p, currency: options.currency }, { index: i, item_list_name: context })
    );
  }

  /**
   * Maps given Adyen payment type id to tracking expected value, or falls back to itself
   * @param paymentType The payment type id to remap
   * @returns The remapped payment type value
   */
  static getAdyenPaymentTypeTrackingValue(paymentType?: string): string {
    return paymentType ? ADYEN_PAYMENTTYPE_MAPPING.get(paymentType) || paymentType : "unknown";
  }

  /**
   * Push given object in datalayer
   * @param payload object with tracking data
   * @param options tracking options
   */
  static track(payload: Record<string, any>, options?: TrackingOptions) {
    if (typeof window !== "undefined" && window.dataLayer) {
      if (options?.ga4 || payload.ecommerce) {
        window.dataLayer.push({ ecommerce: null });
      }
      window.dataLayer.push(payload);
      this._saveLastStatus(payload);
    }
  }

  /**
   * Saves datalayer payload in a global object (used by third parties to retrieve tracking data)
   * @param payload Datalayer payload to reference
   */
  private static _saveLastStatus(payload: Record<string, any>) {
    const globalTrackData = ClientUtils.getAppGlobal("trackings", {});
    globalTrackData.lastStatus = globalTrackData.lastStatus || {};
    let lastEvent = null;
    const pageType = payload.page_type;
    if (pageType) {
      globalTrackData.lastStatus.pageView = payload;
      if (pageType === "category") {
        const isPlp = payload.product_set || payload.page_type_section === "subcategory";
        globalTrackData.lastStatus.pageView.category_type = isPlp ? "PLP" : "Main Category";
      }
      lastEvent = "pageview";
    } else if (payload.event === "add_to_cart") {
      globalTrackData.lastStatus.cartInsertion = payload;
      lastEvent = "add_to_cart";
    }
    if (lastEvent) {
      window.dispatchEvent(new Event(`trackings.savedevent:${lastEvent}`));
    }
  }
}
