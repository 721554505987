import {
  KikBrowserCache,
  KikCacheStrategy,
  KikGcpsid,
  KikHttpConfig,
  KikHttpService,
  KikInMemoryCache,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { DateTime } from "ts-luxon";

import { ApiConstants } from "~/shared/api/api-constants.class";
import { ErrorResponse } from "~/shared/api/error-response.class";

import Logger from "./logger/logger";

export type HttpConfig<T = any> = KikHttpConfig<T>;

export class HttpService extends KikHttpService {
  static get gcpsid() {
    const gcpsid = this._readGcpsid();
    if (!gcpsid) {
      this._syncSession();
    }
    return gcpsid;
  }

  static get errorClass() {
    return this._errorClass;
  }

  protected static get _gcpsidCache(): KikCacheStrategy {
    return this._gcpsidCacheStrategy || this._initGcpsidCache();
  }

  // @override
  protected static _errorClass = ErrorResponse;
  protected static _gcpsidCacheStrategy: KikCacheStrategy;
  protected static _gcpsidHeader: string = "x-gcpsid";
  protected static _gcpsidStorageKey: string = "gcpsid";
  protected static _gcpsidValidityMinutes: number = +process.env.NEXT_PUBLIC_GCPSID_VALIDITY_IN_MINUTES! || 60;
  protected static _lastSyncTimestamp: number = 0;
  protected static _localeHeader: string = "x-locale";
  protected static _synching: Promise<unknown> | void;
  protected static _synchingMaxFrequencyInSeconds: number = 30 * 1000; // 30 seconds

  static {
    this._syncSession();
  }

  protected static _initGcpsidCache(): KikCacheStrategy {
    this._gcpsidCacheStrategy =
      typeof window !== typeof void 0 && typeof localStorage !== typeof void 0
        ? new KikBrowserCache(localStorage, "")
        : new KikInMemoryCache();

    return this._gcpsidCacheStrategy;
  }

  protected static _isApiCall<T>(config: KikHttpConfig<T>) {
    return config.url.startsWith(process.env.NEXT_PUBLIC_API_V2_BASE_URL!);
  }

  protected static async _preprocessRequest<T>(config: KikHttpConfig<T>): Promise<KikHttpConfig<T>> {
    if (this._isApiCall(config)) {
      config.useCredentials = !0; // Send cookie to domain
      // Add gcpsid header at FE
      config.headers = {
        ...config.headers,
        [this._gcpsidHeader]: this._readGcpsid().value,
        [this._localeHeader]:
          typeof location !== "undefined"
            ? `${location?.href}`.match(/\/([a-zA-Z]{2}-[a-zA-Z]{2})\//)?.[1] || "default" // the current locale
            : "server",
      };
    }

    return config;
  }

  protected static async _preprocessResponse<T>(config: KikHttpConfig<T>, res: Response): Promise<Response> {
    if (this._isApiCall(config)) {
      this._writeGcpsid(res.headers.get(this._gcpsidHeader) || void 0);
    }

    return res;
  }

  protected static _readGcpsid(): KikGcpsid {
    const gcpsid = KikGcpsid.parse(this._gcpsidCache.read<KikGcpsid>(this._gcpsidStorageKey) || new KikGcpsid());
    if (gcpsid.expired) {
      this._removeGcpsid();
    }

    return gcpsid;
  }

  protected static _removeGcpsid() {
    try {
      this._gcpsidCache.remove(this._gcpsidStorageKey);
    } catch (e) {
      Logger.instance.debug("HttpService _removeGcpsid errored", e);
    }
  }

  /**
   * Sync the session by calling the gcpsid endpoint.
   * This triggers the logic in HttpService for updating the storage.
   */
  protected static _syncSession(): Promise<unknown> {
    // Throttling
    if (!this._synching && DateTime.now().ts - this._lastSyncTimestamp > this._synchingMaxFrequencyInSeconds) {
      if (this._readGcpsid().expired) {
        this._synching = this.$http({
          url: ApiConstants.endpointsV2.ecoGcpsid,
        })
          .then(() => {
            this._lastSyncTimestamp = DateTime.now().ts;
          })
          .finally(() => {
            this._synching = void 0;
          })
          .catch((e) => {
            Logger.instance.warn("HttpService: failed to sync session", e);
          });
      }
    }

    return this._synching || Promise.resolve();
  }

  protected static _writeGcpsid(newValue: string | void) {
    if (!newValue) {
      return this._removeGcpsid();
    }
    try {
      this._gcpsidCache.write(this._gcpsidStorageKey, {
        value: newValue,
        expiry: DateTime.now().plus({ minutes: this._gcpsidValidityMinutes }).ts,
      });
    } catch (e) {
      Logger.instance.debug("HttpService _writeGcpsid errored", e);
    }
  }
}
