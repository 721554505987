import {
  BaseRecommendRequest,
  FallbackParams,
  FbtModel,
  LookingSimilarModel,
  RecommendationsHit,
  RecommendationsRequest,
  recommendClient,
  RecommendHit,
  RelatedModel,
  TrendingItemsModel,
} from "@algolia/recommend";
import aa from "search-insights";

import Editorials from "~/types/editorials";
import cartUtils from "~/utils/cart-utils";
import productUtils from "~/utils/product-utils";

import Logger from "./logger/logger";

export type RecommenderParams = {
  locale: string;
  productID: string | undefined;
  productReferenceFields: Editorials.ProductsAlgoliaRecommender;
  organizationUnitId: string;
};
export type RecommenderEnrichment = {
  algoliaResponse: RecommendHit[];
  productDetails: any;
};

type ExtendedRecommenderParams = {
  model?: FbtModel | TrendingItemsModel | LookingSimilarModel | RelatedModel;
  objectID?: string;
  fallbackParameters?: FallbackParams;
  queryParameters?: {
    filters?: string;
  };
};

function getUserTokens() {
  let currentUserToken;
  let currentAuthenticatedUserToken;
  aa("getUserToken", {}, (err, userToken) => {
    if (err) {
      Logger.instance.error(err);
      return;
    }
    currentUserToken = userToken;
  });

  aa("getAuthenticatedUserToken", {}, (err, authenticatedUserToken) => {
    if (err) {
      Logger.instance.error(err);
      return;
    }
    currentAuthenticatedUserToken = authenticatedUserToken;
  });

  return {
    currentUserToken,
    currentAuthenticatedUserToken,
  };
}

export function algoliaAddToBag(algoliaIndexId: string, productId: number, trackingData: any, quantity = 1) {
  const { currentUserToken, currentAuthenticatedUserToken } = getUserTokens();

  let eventPayload = {
    userToken: currentUserToken,
    authenticatedUserToken: currentAuthenticatedUserToken,
    eventName: "add to cart after search",
    index: algoliaIndexId,
    objectIDs: [`${productId}`],
    objectData: [
      {
        price: trackingData?.price?.toFixed(2),
        discount: trackingData?.discount ? trackingData?.discount.toFixed(2) : undefined,
        quantity: quantity,
      },
    ],
    value: trackingData?.price,
    currency: trackingData?.currency,
  } as any;

  const currentQueryId = sessionStorage?.getItem("algolia_queryid");
  if (currentQueryId !== null && currentQueryId != "") {
    aa("addedToCartObjectIDsAfterSearch", {
      ...eventPayload,
      queryID: currentQueryId,
    });

    sessionStorage.removeItem("algolia_queryid");
    localStorage.removeItem("algolia_queryid");

    // save added products with relative queryId in localstorage for purchasedObjectIDsAfterSearch event
    const currentCartQueryIds = localStorage?.getItem("algolia_cart_queryids");
    let newAlgoliaCartQueryids = {} as any;
    if (currentCartQueryIds) {
      newAlgoliaCartQueryids = JSON.parse(currentCartQueryIds);
    }
    newAlgoliaCartQueryids[`${productId}`] = currentQueryId;
    localStorage.setItem("algolia_cart_queryids", JSON.stringify(newAlgoliaCartQueryids));
  } else {
    aa("addedToCartObjectIDs", eventPayload);
  }
}

type algoliaOrderData = {
  currency: string;
  total: number;
  items: {
    id: number;
    quantity: number;
    price: number;
    discount: number;
  }[];
};

export function algoliaPurchasedAfterSearch(algoliaIndexId: string, algoliaOrderData: algoliaOrderData) {
  const { currentUserToken, currentAuthenticatedUserToken } = getUserTokens();

  const raw = localStorage?.getItem("algolia_cart_queryids");
  if (raw) {
    const currentCartQueryIds = JSON.parse(raw);
    if (currentCartQueryIds) {
      aa("purchasedObjectIDsAfterSearch", {
        userToken: currentUserToken,
        authenticatedUserToken: currentAuthenticatedUserToken,
        eventName: "Purchase completed after search",
        index: algoliaIndexId,
        objectIDs: algoliaOrderData.items.map((item) => `${item.id}`),
        objectData: algoliaOrderData.items.map((item) => ({
          queryID: currentCartQueryIds[item.id],
          price: item.price.toFixed(2),
          discount: item.discount ? Math.abs(item.discount).toFixed(2) : undefined,
          quantity: item.quantity,
        })),
        value: algoliaOrderData.total,
        currency: algoliaOrderData.currency,
      });

      localStorage.removeItem("algolia_cart_queryids");
    }
  }
}

/**
 * Maps a Next.js/Vercel locale to the corresponding Algolia locale.
 *
 * @param locale - The locale for the current request
 */
export function mapLocale(organizationUnitId: number, locale: string | undefined) {
  if (!locale || !locale.includes("-")) {
    Logger.instance.error(`Invalid locale ${locale} found in request, this will likely error out.`);
    return `${organizationUnitId}_${locale}`;
  }

  const [language, country] = locale.split("-");

  return `${organizationUnitId}_${language}-${country.toUpperCase()}`;
}

export function highlightToHtml(value: string): string {
  return value.replace(/__aa-highlight__/g, "<strong>").replace(/__\/aa-highlight__/g, "</strong>");
}

export async function getRecommender(params: Partial<RecommendationsRequest>) {
  try {
    const client = recommendClient(process.env.NEXT_PUBLIC_ALGOLIA_APPID!, process.env.NEXT_PUBLIC_ALGOLIA_APIKEY!);
    const response = await client.getRecommendations({
      requests: [params as RecommendationsRequest],
    });
    return response.results[0].hits;
  } catch (error) {
    Logger.instance.error(error);
  }
}

export function mergeHistWithProductsInformation(
  data: RecommendHit[],
  productsInformation: EVA.Core.SearchProductsResponse
): any[] {
  const previousData = [...data];
  const productList = productsInformation?.Products?.map((item: any) => {
    return {
      ...item,
      product_id: item.product_id,
      prices: { ...item },
      has_stock: productsInformation?.Options?.AvailabilityResult?.[item.product_id]?.Delivery?.HasStock ?? false,
    };
  });
  const mergedData = previousData.reduce((list: any[], item: any) => {
    const enrichment = productList?.find((product: any) => `${product.product_id}` === `${item.objectID}`);
    if (enrichment) {
      list.push({ ...item, ...enrichment });
    }
    return list;
  }, []);
  return mergedData;
}

const getRecommendParams = (
  baseParams: BaseRecommendRequest,
  carouselParams: ExtendedRecommenderParams,
  carouselType: FbtModel | TrendingItemsModel | LookingSimilarModel | RelatedModel
): Partial<RecommendationsRequest> => {
  switch (carouselType) {
    case "trending-items":
      return {
        ...baseParams,
        model: carouselParams.model!,
        fallbackParameters: carouselParams.fallbackParameters,
        queryParameters: carouselParams.queryParameters,
      };
    case "looking-similar":
    case "bought-together":
    case "related-products":
      return {
        ...baseParams,
        model: carouselParams.model!,
        objectID: carouselParams.objectID,
        fallbackParameters: carouselParams.fallbackParameters,
        queryParameters: carouselParams.queryParameters,
      };
    default:
      throw new Error("Unknown carousel type");
  }
};
export async function recommenderRequest({
  productReferenceFields,
  locale,
  productID,
  organizationUnitId,
}: RecommenderParams): Promise<RecommendationsHit[] | undefined> {
  const carouselParams = productReferenceFields;
  const localeData = locale.split("-");
  const index = `${organizationUnitId}_${localeData[0]}-${localeData[1].toUpperCase()}`;
  const carouselType = carouselParams.type as FbtModel | TrendingItemsModel | LookingSimilarModel | RelatedModel;
  const baseRecommenderParams: BaseRecommendRequest = {
    indexName: index,
    threshold: carouselParams.threshold,
    maxRecommendations: carouselParams?.limit ?? 30,
  };
  const extendedParams: ExtendedRecommenderParams = {
    model: carouselType,
    objectID: productID,
  };
  if (!!carouselParams.fallbackParameters) {
    extendedParams.fallbackParameters = {
      filters: carouselParams.fallbackParameters,
    };
  }
  if (!!carouselParams.filterParameters) {
    extendedParams.queryParameters = {
      filters: carouselParams.filterParameters,
    };
  }
  const recommenderParams = getRecommendParams(baseRecommenderParams, extendedParams, carouselType);
  const response = await getRecommender(recommenderParams);
  return response;
}

export function cartRecommenderElegibleProduct(cart: EVA.Core.ShoppingCartResponse | undefined): string | undefined {
  if (!cart || cart?.ShoppingCart?.Lines?.length === 0) {
    return undefined;
  } else {
    const filteredProducts = cart.ShoppingCart.Lines.filter((item) => {
      const product = item.Product;
      return (
        product &&
        !cartUtils.isProductFree(item) &&
        !productUtils.isGiftCard(product?.Properties) &&
        !productUtils.isVirtualProduct(product?.Properties)
      );
    });
    //@ts-ignore
    return filteredProducts.length > 0 ? `${filteredProducts.at(-1).Product.ID}` : undefined;
  }
}
