import { ImageLoaderProps } from "next/image";

export default function versionedImageLoader({ src, width, quality }: ImageLoaderProps) {
  const params = new URLSearchParams({
    url: src,
    q: `${quality || 75}`,
    w: `${width}`,
  });

  if (process.env.NEXT_PUBLIC_ASSETS_VERSION) {
    params.set("av", process.env.NEXT_PUBLIC_ASSETS_VERSION!);
  }

  return `/_next/image?${params}`;
}
