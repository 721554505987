import { KikGenericResponse } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { DateTime } from "ts-luxon";

import { HttpService } from "~/services/http";
import Logger from "~/services/logger/logger";
import { ApiConstants } from "~/shared/api/api-constants.class";

import Constants from "./constants";

interface MappedCarrierIds {
  carrierIds: string[];
}

const optimalCarrierStrategies: Record<
  string,
  (orderId: number, organizationUnitId: number) => Promise<MappedCarrierIds>
> = {
  v2(orderId: number, organizationUnitId: number) {
    return HttpService.$http<KikGenericResponse<MappedCarrierIds>>({
      url: ApiConstants.endpointsV2.ecoOptimalCarrier,
      queryParams: {
        orderId,
        organizationUnitId,
      },
    }).then((response) => response.data!);
  },
};

const orderUtils = {
  calculateTotals(order: EVA.Core.OrderDto, amounts: EVA.Core.OpenOrderAmounts, withTax?: boolean) {
    const discountLines = amounts.Discounts.PerDiscount;

    const subtotal = amounts.Types.NormalProduct
      ? withTax
        ? Math.abs(amounts.Types.NormalProduct.Amount)
        : Math.abs(amounts.Types.NormalProduct.InTax)
      : 0;
    const total = Math.abs(amounts.Total.InTax);
    const taxTotal = withTax ? Math.abs(amounts.Total.Tax) : 0;
    const giftWrapping = amounts.Types.GiftWrappingCosts
      ? withTax
        ? Math.abs(amounts.Types.GiftWrappingCosts.Amount)
        : Math.abs(amounts.Types.GiftWrappingCosts.InTax)
      : 0;

    const shippingCostsLine = orderUtils.getShippingLine(order);

    const shipping = shippingCostsLine
      ? withTax
        ? Math.abs(shippingCostsLine.NetTotalAmount)
        : Math.abs(shippingCostsLine.NetTotalAmountInTax)
      : 0;

    const shippingMethod =
      order.Lines.find((line) => line.ShippingMethod && !line.IsCancelled)?.ShippingMethod!.Name || null;

    return {
      total,
      subtotal,
      discountLines: discountLines,
      giftWrapping,
      shipping,
      tax: taxTotal,
      estimated: false,
      shippingMethod,
    };
  },
  getOrderReturnWindowDays(): number {
    return Math.floor(Number(process.env.NEXT_PUBLIC_RETURN_WINDOW_DAYS)) ?? Constants.DEFAULT_RETURN_WINDOW_DAYS;
  },
  getOrderReturnMaxDate(deliveryDate: string): Date {
    return DateTime.fromISO(deliveryDate).plus({ days: this.getOrderReturnWindowDays() }).toJSDate();
  },
  getOrderReturnDaysFromNow(deliveryDate: string): number {
    return Math.ceil(DateTime.fromJSDate(this.getOrderReturnMaxDate(deliveryDate)).diff(DateTime.now(), "days").days);
  },
  getShippingLine(order: EVA.Core.OrderDto): EVA.Core.OrderLineDto | undefined {
    return order.Lines.find((line) => line.Type === 5 && !line.IsCancelled);
  },
  isOrderEligibleForReturn(deliveryDate: string): boolean {
    return this.getOrderReturnDaysFromNow(deliveryDate) >= 0;
  },
  isReturn(order: EVA.Core.OrderDto): boolean {
    return order.TotalAmountInTax < 0;
  },
  getOptimalCarrier: (orderId: number, organizationUnitId: number): Promise<MappedCarrierIds> => {
    // TODO: THIS MUST COME FROM AN APP SETTING, CAUSE WE WILL HAVE MULTIPLE PROVIDERS (E.G. IQF FOR MIDDLE EAST)
    const optimalCarrierStrategy =
      optimalCarrierStrategies[process.env.NEXT_PUBLIC_OPTIMAL_CARRIER_STRATEGY!] || optimalCarrierStrategies.v2;

    return optimalCarrierStrategy(orderId, organizationUnitId).catch((e) => {
      Logger.instance.warn("optimal carrier call failed", e);
      return {
        carrierIds: [] as string[],
      };
    });
  },
};

export default orderUtils;
