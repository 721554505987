import { bootstrapEndpoint, EvaService } from "@springtree/eva-sdk-core-service";
import { Core, createServiceDefinition, IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniq } from "ramda";

import Logger from "~/services/logger/logger";
import { Coords } from "~/shared/coords.class";
import CommonUtils from "~/utils/common-utils";
import Constants from "~/utils/constants";
import invariant from "~/utils/invariant";

export default class Eva {
  static DEFAULT_CATEGORY_SORT: EVA.Core.SortFieldDescriptor[] = [{ FieldName: "display_value", Direction: 0 }];
  private static DEFAULT_SEARCH_PRODUCTS_REQUEST = {
    IncludedFields: Constants.QUERY_INCLUDED_FIELDS,
    Options: {
      IncludePrefigureDiscounts: true,
      AvailabilityOptions: {
        Delivery: {
          AvailabilityDate: true,
        },
        Pickup: {
          IncludePickupOrganizationUnits: false,
          IncludeOrganizationUnitsWithoutStock: true,
        },
      },
    },
    Filters: {
      is_sellable: {
        Values: [true],
        IncludeMissing: false,
      },
      display_price: {
        From: "0",
        IncludeMissing: false,
      },
    },
  } satisfies EVA.Core.SearchProducts;

  static async getOrganizationUnitId(locale: string) {
    const eva = new Eva({ locale });
    const units = await eva.listOrganizationUnitSummaries();
    const country = locale.split("-")[1];
    const unit = units.find((w) => w.CountryID.toLowerCase() === country.toLowerCase());

    invariant(unit, `Could not find unit for country ${country}.`);

    return unit.ID;
  }

  protected locale: string | undefined;
  protected organizationUnitId: number | undefined;
  protected userToken: string | undefined;

  constructor({
    locale,
    organizationUnitId,
    userToken,
  }: { locale?: string; organizationUnitId?: number; userToken?: string } = {}) {
    this.locale = locale;
    this.organizationUnitId = organizationUnitId;
    this.userToken = userToken;
  }

  static buildSSOLoginPayload(id_token: string, provider: number): EVA.Core.Login {
    return {
      AsEmployee: false,
      SelectFirstOrganizationUnit: true,
      CustomAuthenticatorType: "OpenID",
      CustomAuthenticateData: {
        id_token,
        provider,
      },
    };
  }

  /**
   * This function enriches the order response by adding parent products to each line item.
   * @param {EVA.Core.GetOrderResponse | undefined} response - The order response to be enriched.
   * @returns {EVA.Core.GetOrderResponse | undefined} - The enriched order response.
   */
  async enrichGetOrderResponseWithParents(response?: EVA.Core.GetOrderResponse) {
    // If the response or its lines are not defined, return the response as is.
    if (!response?.Result?.Lines?.length) {
      return response;
    }

    // Clone the lines from the response.
    const lines = [...response.Result.Lines];

    // Extract all unique parent product IDs from the lines
    const allParentProductIds = uniq(
      lines
        .map((line: EVA.Core.OrderLineDto) => {
          // Return the parent product IDs array of the line
          return line?.Product?.Properties?.parent_product_ids;
        })
        // flatten as it returns an array
        .flat()
        // filter all undefined values
        .filter((id) => Boolean(id))
    );

    // If no detail is found, don't even proceed with the enrichment
    if (!allParentProductIds || allParentProductIds.length === 0) {
      return undefined;
    }

    // Fetch all parent products using the extracted IDs.
    const parentProductsDetails = await this.getProducts(allParentProductIds)
      .then((resp) => Object.entries(resp?.Products).flatMap((p) => p[1]))
      .catch((err) => Logger.instance.error("eva-utils.ts > enrichGetOrderResponseWithParents errored", err));

    // If no detail is found, don't even proceed with the enrichment
    if (!parentProductsDetails) {
      return undefined;
    }

    // Clone the lines for modification and iterate over each one
    const enrichedLines = lines.map((line) => {
      // Iterate over each parent product
      for (const parentProduct of parentProductsDetails) {
        // If parent product id is included into line.Product.Properties.parent_product_ids, enrich the line data with its details
        if (line.Product?.Properties?.parent_product_ids?.includes(parentProduct.product_id as string)) {
          line.Product.Properties.parent_products_details = [];
          line.Product.Properties.parent_products_details.push(parentProduct);
        }
      }
      return line;
    });

    // Replace the lines in the response with the new lines.
    response.Result.Lines = enrichedLines;

    // Return the enriched response.
    return response;
  }

  async getGroupedProductSlugs(page = 1) {
    const response = await this.getService(Core.SearchProducts, {
      Page: page,
      PageSize: 100,
      IncludedFields: ["slug", "logical_level_hierarchy.product_id"],
      Filters: {
        slug: {
          IncludeMissing: false,
        },
        logical_level: {
          Values: ["color"],
          IncludeMissing: true,
        },
        is_sellable: {
          Values: [true],
          IncludeMissing: false,
        },
      },
      Sort: [
        { FieldName: "logical_level_hierarchy.product_id", Direction: 0 },
        { FieldName: "color", Direction: 0 },
      ],
    });

    return response!;
  }

  async getProductsByIds(
    parentIds: string[],
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const response = await this.getService(Core.SearchProducts, {
      ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST,
      Page: page,
      PageSize: pageSize,
      Sort: sort,

      Filters: {
        ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST.Filters,
        product_id: {
          Values: parentIds,
        },
      },
    });

    return response!;
  }
  async getProductsByCategory(
    category: string,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const response = await this.getService(Core.SearchProducts, {
      ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST,
      Page: page,
      PageSize: pageSize,
      Sort: sort,

      Filters: {
        ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST.Filters,
        tags: {
          Values: [category],
        },
        logical_level: {
          Values: ["root"],
          IncludeMissing: true,
        },
      },
    });

    return response!;
  }
  async getProductsByQuery(
    query: string,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const response = await this.getService(Core.SearchProducts, {
      ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST,
      Page: page,
      PageSize: pageSize,
      Sort: sort,

      Query: query,

      Filters: {
        ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST.Filters,
        logical_level: {
          Values: ["root"],
          IncludeMissing: true,
        },
      },
    });

    return response!;
  }
  async getProductsBySet(
    setId: string,
    sort = Eva.DEFAULT_CATEGORY_SORT,
    page = 1,
    pageSize = Constants.DEFAULT_PAGE_SIZE
  ) {
    const response = await this.getService(Core.SearchProducts, {
      ...Eva.DEFAULT_SEARCH_PRODUCTS_REQUEST,
      Page: page,
      PageSize: pageSize,
      Sort: sort,

      // @ts-expect-error
      ProductSearchTemplateID: setId,
    });

    return response!;
  }

  async getChildrenProducts(parentIds: string[], page = 1, pageSize = Constants.DEFAULT_PAGE_SIZE) {
    const response = await this.getService(Core.SearchProducts, {
      Page: page,
      PageSize: pageSize,
      Filters: {
        parent_product_ids: {
          Values: parentIds,
        },
      },
      Sort: [{ FieldName: "color", Direction: 0 }],
      IncludedFields: Constants.QUERY_CHILDREN_INCLUDED_FIELDS,
      Options: {
        IncludePrefigureDiscounts: true,
        AvailabilityOptions: {
          Delivery: {
            AvailabilityDate: true,
          },
        },
      },
    });

    return response!;
  }

  async getProductsFromBackendId(backendIds: string[]) {
    const response = await this.getService(Core.SearchProducts, {
      IncludedFields: ["slug"],
      Filters: {
        backend_id: {
          Values: backendIds,
          ExactMatch: true,
          IncludeMissing: false,
        },
      },
      Options: {
        AvailabilityOptions: {
          Delivery: {
            AvailabilityDate: true,
          },
          Pickup: {
            AvailabilityDate: false,
          },
        },
      },
    });

    return response!;
  }

  async getProductsFromFilters(filters: { [key: string]: EVA.Core.FilterModel }) {
    const response = await this.getService(Core.SearchProducts, {
      Filters: filters,
      IncludedFields: Constants.QUERY_INCLUDED_FIELDS,
    });

    return response!;
  }

  /**
   * @see https://dora.on-eva.io/ListOrganizationUnitSummaries
   * @see https://docs.newblack.io/documentation/dev/reference/list-organization-unit-summaries
   */
  async listOrganizationUnitSummaries() {
    const response = await this.getService(Core.ListOrganizationUnitSummaries, {
      PageConfig: {
        Filter: {
          TypeID: 2,
          OrganizationUnitSetID: parseInt(process.env.NEXT_PUBLIC_EVA_ORGANIZATION_SET_ID!),
        },
      },
    });

    return response!.Result.Page;
  }

  /**
   * @see https://dora.on-eva.io/GetApplicationConfiguration
   * @see https://docs.newblack.io/documentation/dev/reference/get-application-configuration#getapplicationconfiguration
   */
  async getApplicationConfiguration() {
    const response = await this.getService(Core.GetApplicationConfiguration);

    return response!.Configuration;
  }

  async setShippingMethod(orderId: number, shippingMethodId: number) {
    return await this.getService(Core.SetShippingMethod, {
      OrderID: orderId,
      ShippingMethodID: shippingMethodId,
    });
  }

  async getProductAvailability(
    products: EVA.Core.GetProductAvailability.Product[] | EVA.Core.OrderLineDto[],
    includeStores = true,
    availablesOnly = false,
    userLocation?: Coords
  ) {
    let productAvailabilityPayload: EVA.Core.GetProductAvailability = {
      Products: products,
      Options: {
        Pickup: {
          IncludePickupOrganizationUnits: includeStores,
          IncludeOrganizationUnitsWithoutStock: !availablesOnly,
        },
        Delivery: {
          AvailabilityDate: true,
        },
      },
    };
    if (userLocation && userLocation.lat && userLocation.lng) {
      productAvailabilityPayload.Options!.Pickup!.InRadius = {
        Center: {
          Latitude: userLocation?.lat,
          Longitude: userLocation?.lng,
        },
        CountryID: this.locale?.split("-")[1]?.toUpperCase(),
      };
    }

    const response = await this.getService(Core.GetProductAvailability, productAvailabilityPayload);

    return response;
  }

  /**
   * Retrieves data from a specific Eva service using the specified payload.
   *
   * @param serviceDefinition - An Eva service definition
   * @param payload - The payload to include in the request
   * @param preprocessFetch - Used to customize fetch request before sending
   * @returns the requested data
   */
  protected async getService<T extends IEvaServiceDefinition>(
    serviceDefinition: new () => T,
    payload?: T["request"],
    preprocessFetch?: (req: Request) => void
  ) {
    const endpoint = await bootstrapEndpoint({
      uri: process.env.NEXT_PUBLIC_EVA_BACKEND_URL!,
    });

    const service = new EvaService(createServiceDefinition(serviceDefinition), endpoint);

    service.setRequest(payload);

    const fetchRequest = service.buildFetchRequest();

    if (this.locale) {
      fetchRequest.headers.set("Accept-Language", this.locale);
    }

    if (this.userToken) {
      fetchRequest.headers.set("Authorization", this.userToken);
    }

    if (this.organizationUnitId) {
      fetchRequest.headers.set("eva-requested-organizationunitid", this.organizationUnitId.toString());
    }

    if (CommonUtils.parseBoolean(process.env.NEXT_PUBLIC_EVA_STRING_IDS)) {
      fetchRequest.headers.set("EVA-IDs-Mode", "StringIDs");
    }

    if (typeof preprocessFetch === "function") {
      preprocessFetch(fetchRequest);
    }

    const res = await fetch(fetchRequest);
    const resClone = res.clone();

    return (await res.json().catch(async () => await resClone.text())) as T["response"];
  }

  /**
   * Retrieves the detail for the product with the given id
   * @param productId - The product id
   * @returns the detail for the requested product
   */
  async getProductDetail(productId: string): Promise<EVA.Core.GetProductDetailResponse> {
    // convert productId to number, even if using strings with KIKO ids would be ideal
    const idAsNumber = Number.parseInt(productId);
    const response = await this.getService(Core.GetProductDetail, { ID: idAsNumber });
    invariant(response, "Did not get response from `getProductDetail`");

    return response!;
  }

  /**
   * This asynchronous function retrieves product information based on the provided product IDs.
   *
   * @async
   * @function getProducts
   * @param {number | number[] | string | string[]} productIds - A single or an array of product IDs. These can be either numbers or strings.
   * @returns {Promise<EVA.Core.GetProductsResponse>} Returns a promise that resolves to the response from the `getProducts` service. The response includes the backend_id of the products.
   * @throws Will throw an error if the response from `getProducts` is not received.
   */
  async getProducts(productIds: number | number[] | string | string[]): Promise<EVA.Core.GetProductsResponse> {
    const idsAsArray = Array.isArray(productIds) ? [...productIds] : [productIds];
    const idsAsNumbers = idsAsArray.map((pid: number | string) => pid as number);
    const response = await this.getService(Core.GetProducts, {
      ProductIDs: idsAsNumbers,
      IncludedFields: ["backend_id"],
    });
    invariant(response, "Did not get response from `getProducts`");

    return response!;
  }

  /**
   * Retrieves the discounts eligible for a specific product that contains PromotionLabel
   * @param productId - The product id
   * @returns the array of filtered discounts
   */
  async getProductDiscounts(productId: string) {
    // convert productId to number, even if using strings with KIKO ids would be ideal
    const idAsNumber = Number.parseInt(productId);
    const response = await this.getService(Core.PrefigureDiscounts, { ProductIDs: [idAsNumber] });
    invariant(response, "Did not get response from `PrefigureDiscounts`");

    return response.Results.filter(
      (discount) => discount.PromotionLabel && discount.EligibleProductIDs.includes(idAsNumber)
    ).map((d) => ({
      DiscountID: d.DiscountID,
      PromotionLabel: d.PromotionLabel,
    }));
  }

  /**
   * Retrieves the detail for the product with the given id, if it has a 'color' logical_level; retrieves its root otherwise
   *
   * @param productId - The product id
   * @returns the detail for the root of the requested product
   */
  async getRootProductDetail(productId: string) {
    const response = await this.getProductDetail(productId);

    if (response.Result?.logical_level === "color") {
      return await this.getProductDetail(
        response.Result.logical_level_hierarchy.find((e: { name: string }) => e.name === "root")?.product_id
      );
    }

    return response!;
  }

  // TODO need to understand which approach to follow in order to get all the stores nearby a specific area
  async listShopsByProximity(data: any) {
    const response = await this.getService(Core.ListShopsByProximity, {
      ...data,
      PageConfig: {
        Limit: 100,
      },
    });
    // TODO remove this once EVA fix the filter by country
    return response?.Result.Page
      ? Object.values(response.Result.Page).filter(
          (store) => store?.Address?.CountryID == this.locale?.split("-")[1]?.toUpperCase()
        )
      : undefined;
  }

  async getShoppingCart(cartId: number) {
    const response = await this.getService(Core.GetShoppingCart, {
      AdditionalOrderDataOptions: {
        IncludeAvailablePaymentMethods: false,
        IncludeAvailableRefundPaymentMethods: false,
        IncludeAvailableShippingMethods: false,
        IncludeCheckoutOptions: false,
        IncludeCustomerCustomFields: false,
        IncludeGiftCardBusinessRules: true,
        IncludeGiftWrapping: false,
        IncludeOrganizationUnitData: false,
        IncludePaymentTransactionActions: false,
        IncludePickProductOptions: false,
        IncludePrefigureDiscounts: false,
        IncludeProductRequirements: false,
        IncludeValidateShipment: false,
        ProductProperties: [],
      },
      OrderID: cartId,
    });
    return response?.ShoppingCart ? Response : undefined;
  }
}
