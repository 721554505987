import React, { DetailedHTMLProps, useEffect, useRef, VideoHTMLAttributes } from "react";

type Props = DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;

const VideoLazyLoad = ({ src, ...props }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video: HTMLVideoElement | null = videoRef.current;

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && video && src) {
            (video as HTMLVideoElement).src = src;
            observer.unobserve(video);
          }
        });
      },
      {
        rootMargin: "100%",
        threshold: 0,
      }
    );

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (observer && video) {
        observer.unobserve(video);
      }
    };
  }, [src]);

  return <video ref={videoRef} {...props} />;
};

export default VideoLazyLoad;
